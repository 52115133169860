import React from 'react';
import {Carousel } from 'antd';
import { images } from '../../../constants';
import Hero1 from './Hero1';

const Hero = () => {
  const  herotitle1= "FOU (Market)"
  const  herotext1= 
  "Our brand FOU is an Izon name meaning 'MARKET'. It's intents is to bring the local market to our door steps technologically, foster growth in the agric-Tech institution of the local community. Your premier destination for locally-sourced treasures. From farm-fresh produce to handcrafted goods, support local artisans and farmers while indulging in the finest our state has to offer. Shop local, shop from FOU, and embrace the spirit of our vibrant community"
  const  herotitle2= "Become a seller"
  const  herotext2= "Unlock new avenues for your local business growth with FOU, Join our vibrant community of traders and artisans. Elevate your brand, expand your reach, and thrive in the heart of our community with FOU."
  const  herotitle3= "Easy shopping"
  const  herotext3= "FOU bridges the gap between technology and accessibility, ensuring that everyone, regardless of tech-savviness, can effortlessly connect and thrive in our vibrant marketplace. With intuitive user interfaces and comprehensive support. Join us and experience the seamless journey of connecting with buyers and fellow traders, empowering everyone to participate and prosper in our local community."
  return (
  <section className='md:min-h-screen lg:min-h-screen xl:min-h-screen max-h-screen text-white mb-10px md:mb-[180px] lg:mb-[0px] rounded-lg'>
    <Carousel 
    autoplay
    draggable={true} 
    dots={true}
    dotPosition='bottom'
    autoplaySpeed={5000}>
    <Hero1 image={images.farmer3} text={herotext1} title={herotitle1}/>
    <Hero1 image={images.fishmarket} text={herotext2} title={herotitle2}/>
    <Hero1 image={images.market5} text={herotext3} title={herotitle3}/>
   </Carousel>
    </section>

  )
}

export default Hero
