import React, { useEffect }  from 'react';
import {useForm} from "react-hook-form";
import { Link,useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import MainLayout from '../../components/MainLayout';
import { login } from '../../services/users';
import { useDispatch , useSelector} from 'react-redux';
import { userActions } from '../../store/reducers/userReducers';
import videoFile from '../../assets/video/backgroundvideo.mp4';
import '../../../src/index.css';

const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState = useSelector(state => state.user)

   const {mutate, isLoading} = useMutation({
        mutationFn:({phoneNumber, password})=>{
            return login({phoneNumber, password});
        },
        onSuccess: (data) => {
           dispatch(userActions.setUserInfo(data));
            localStorage.setItem('account', JSON.stringify(data));
        },
        onError: (error)=>{
            toast.error("Unable to login")
            console.log(error)
        }
    })

    useEffect(()=>{
        if(userState?.userInfo){
            if(userState?.userInfo?.data?.admin ){
                navigate("/admin");
            }
           
             if(userState?.userInfo?.data?.vendor){
                navigate("/seller");
            }
            if(userState?.userInfo?.data?.admin && userState?.userInfo?.data?.vendor){
                navigate("/admin");
            }
            if(!userState?.userInfo?.data?.admin && !userState?.userInfo?.data?.vendor){
                navigate("/buyer");
            }
            
        }
        

    }, [navigate,userState?.userInfo])

    const { 
        register,handleSubmit,
        formState: { errors, isValid},
        
    } = useForm({
        defaultValues:{
            phoneNumber: "",
            password: "",
            
        },
        mode: "onChange",
    })

    const submitHandler = (data)=>{
        const {phoneNumber, password}=data;
        mutate({phoneNumber, password})
    }
  return (
   <MainLayout>
    <div className="video-background">
      <video autoPlay loop muted>
        <source src={videoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
      <section className='container mx-auto px-5 py-10'>
        <div className='max-w-sm mx-auto'>
        
        <form onSubmit={handleSubmit(submitHandler)} className='bg-offwhite p-8 rounded-lg'>
        <h1 className='text-4xl font-bold text-center text-black mb-8 '>
            WELCOME BACK
        </h1>
            <div className='flex flex-col mb-6 w-full'>
             

            <label htmlFor='email' className='text-[#5a7184] mt-3 font-semibold block'>
                Phone Number
            </label>
            <input {...register("phoneNumber", {
                required:{
                    value: true,
                    message: "Phone Number is required",
                },
                pattern: {
                    value: /^(?:\d{2}([-.])\d{3}\1\d{3}\1\d{3}|\d{11})$/,
                    message: 'Please enter a valid phone number',
                },
            })}
            type="phoneNumber" id='phoneNumber' placeholder='Enter PhoneNumber'
            className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-semibold block outline-none border ${errors.phoneNumber ? "border-red-500" : "border-[#c3cad]"}`}/>
            {errors.phoneNumber?.message && (
                <p className='text-red-500 text-xs mt-1'> {errors?.phoneNumber.message}</p>
            ) }

            <label htmlFor='password' className='text-[#5a7184] font-semibold block mt-3'>
                Password
            </label>
            <input {...register("password",{
                required: {
                    value: true,
                    message: "Password is required",
                },
                minLength:{
                    value: 6,
                    message: "Password length must be atleast 6 characters",
                }
            })} type="password" id='password' placeholder='Enter password'
            className={`placeholder:text-[#959ead] text-black mt-3 rounded-lg px-5 py-4 font-semibold block outline-none border ${errors.password ? "border-red-500" : "border-[#c3cad]"}`}/>
            {errors.password?.message && (
                <p className='text-red-500 text-xs mt-1'> {errors?.password.message}</p>
            ) }
          
            <Link to="" className='text-eni_orange mt-3 font-semibold'>
                Forgot Password?
            </Link>

            <button  type="submit" disabled={!isValid || isLoading}
            id='submit' 
            className=' bg-eni_orange text-white text-2xl mt-3 rounded-lg px-5 py-4 font-semibold block outline-none border border-[#c3cad] my-6 disabled:opacity-70 disabled:cursor-not-allowed'>Sign In</button>
            <div>
                <p className='text-gray-600'>Don't have an account yet ?<Link to="/signup" className='text-eni_green font-semibold'> Register Now</Link></p>
            </div>
           
            </div>
        </form>
        </div>
    </section>
      </div>
    </div>
   </MainLayout>
    
  )
}

export default LoginPage
