import { Outlet, useNavigate } from "react-router-dom";
import Header from "./components/header/Header";
import { useQuery } from "@tanstack/react-query";
import { getLoggedInUser } from "../../services/users";
import {AiOutlineLoading3Quarters} from "react-icons/ai"
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

const AdminLayout = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);

  const {
    data:profileData,
    isLoading: profileIsLoading,
    error:profileError
  } = useQuery({
    queryFn: () => {
     return getLoggedInUser({ token: userState.userInfo?.data?.token });
    },
    queryKey: ["user"],
    onSuccess: (ProfileData) => {
      if (ProfileData?.data?.admin || ProfileData?.data?.vendor || !ProfileData) {
        navigate("/");
        toast.error("Unauthorized");
      }
    },
    onError: (err) => {
      console.log(err);
      navigate("/");
      toast.error("Access Denied... Contact Support");
    },
  });

  if (profileIsLoading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <AiOutlineLoading3Quarters size={50} className="animate-spin  text-slate-700"/>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen lg:flex-row">
      <Header/>
      <main className="bg-[#F9F9F9] flex-1 p-4 lg:p-6">
       
        <div className="text-2xl text-black font-bold">Hi, {profileData?.data?.firstname}</div>
        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;
