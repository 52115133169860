import React from 'react';
import { FaFacebookSquare,FaWhatsappSquare } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";


const SocialShareButtons = ({url, title}) => {
  return (
    <div className='max-w-xs flex justify-between'>
      <a target="_blank" href={`https://www.facebook.com/dialog/share?app_id=1180206992856877&display=popup&href=${url}`}
       rel="noreferrer"> 
        <FaFacebookSquare className='text-[#3b5998] w-8 h-auto rounded-full'/>
      </a>
      <a target="_blank" href={`https://twitter.com/intent/tweet?url=${url}`}
       rel="noreferrer">
        <FaXTwitter className='text-[#00acee] w-8 h-auto rounded-full'/>
      </a>
      <a target="_blank" href={`https://api.whatsapp.com/send/?text=${url}`} 
      rel="noreferrer">
        <FaWhatsappSquare className='text-[#25D366] w-8 h-auto rounded-full '/>
      </a>
    </div>
  )
}

export default SocialShareButtons
