import React from "react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { getSinglePostV1, updatePost } from "../../../../services/youtube";
import ArticleDetailSkeleton from "../../../articleDetail/components/ArticleDetailSkeleton";
import ErrorMessage from "../../../../components/ErrorMessage";



const EditYoutubeV2 = () => {
    const { id } = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const userState = useSelector((state) => state.user);
    const [newDescription, setNewDescription] = useState("");
    const [url, setNewUrl] = useState("")
    let loadingToast = "";
    
   
    //get the specific post by id to prepopulate the update fields
    const { data, isLoading, isError } = useQuery({
      queryFn: () => getSinglePostV1({ id }),
      queryKey: ["article", id],
      onSuccess: (data) => {
        setNewDescription(data?.data?.description || 'Empty');
        setNewUrl(data?.data?.url)
      },
      refetchOnWindowFocus: false,
    });
    

// mutation for updating the post
    const {
      mutate: mutateUpdatePostDetail,
      isLoading: isLoadingUpdatePostDetail,
    } = useMutation({
      mutationFn: ({url, newDescription, id, token}) => {
        return updatePost({
          url,
          newDescription,
          id,
          token,
        });
      },
     
      onSuccess: (data) => {
        queryClient.invalidateQueries(["article", id]);
        //toast.success("Post is updated");
        navigate(`/admin/youtube/manage`, { replace: true });
      },
      onError: (error) => {
        toast.error(error.message);
        console.log(error);
      },
      onSettled: () => {
        // Optional: Perform any additional actions after mutation is settled
      },
    });
  
    // handle post will trigger once the update button is clicked 
    const handleUpdatePost = async (e) => {
      try{
        
          // Show loading state
          loadingToast = toast.loading("Updating Youtube ...");
      
        mutateUpdatePostDetail({
         url,
         newDescription,
         id,
         token: userState.userInfo?.data?.token,
       });
      
       // Hide loading state on success
      toast.success("Youtube updated successfully");
       // Clear the loading state manually
      toast.dismiss(loadingToast);
      }catch (error) {
        // Handle errors during the update
      toast.error("Error updating Youtube");
        console.error(error);
       
    };
  }

    let isPostDataLoaded = !isLoading && !isError;

    const isFormValid = newDescription.trim() !== ""  &&  url.trim() !== "" 
    return (
      <div>
        <div className="text-gray-600"> EDIT YOUTUBE LINK </div>
        {isLoading ? (
          <ArticleDetailSkeleton />
        ) : isError ? (
          <ErrorMessage message="Couldn't fetch the post detail" />
        ) : (
          <section className="container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
          <article className="flex-1">
  <div className="w-full">
    <label className="d-label" htmlFor="title">
      <span className="d-label-text">Description: </span>
    </label>
    <input
      type="text"
      id="newDescription"
      value={newDescription}
      className="border border-none text-xl font-sm font-roboto text-dark-hard rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500"
      onChange={(e) => setNewDescription(e.target.value)}
      placeholder="Enter Description"
    />
  </div>
  <div className="w-full mt-4">
    <label className="d-label" htmlFor="url">
      <span className="d-label-text">URL: </span>
    </label>
    <input
      type="text"
      id="url"
      value={url}
      className="border border-none text-xl font-sm font-roboto text-dark-hard rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500"
      onChange={(e) => setNewUrl(e.target.value)}
      placeholder="Enter URL"
    />
  </div>
  <button
    disabled={!isFormValid || isLoadingUpdatePostDetail}
    type="button"
    onClick={handleUpdatePost}
    className="mt-4 ml-auto bg-green-500 text-white font-semibold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70 focus:outline-none focus:ring-2 focus:ring-green-500"
  >
    {isLoadingUpdatePostDetail ? "Updating..." : "Update Link"}
  </button>
</article>

          </section>
        )}
      </div>
    );
  };

export default EditYoutubeV2;