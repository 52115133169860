import React from 'react';
import { Hero} from 'react-daisyui';
import { useTheme } from './ThemeContext';

const ExecutiveHero = ({pageTitle, PageIntro}) => {
  const {darkMode} = useTheme()
    const args = {
        className: "bg-opacity-60"
    }
  return (
    <div className=''>
  <Hero className="bg-opacity-50 min-h-[150px] bg-eni_green" >
      <Hero.Overlay {...args} />
      <Hero.Content className="text-center  max-h-[110px]">
        <div className={`max-w-full ${darkMode ? "text-neutral-200 opacity-90":"text-neutral-300 opacity-90"}`}>
          <h1 className="mt-16 md:mt-4 lg:mt-4 text-xl md:text-2xl lg:text-3xl font-bold ">{pageTitle}</h1>
          <p className="py-2 text-lg md:text">
           {PageIntro}
          </p>
        </div>
      </Hero.Content>
    </Hero>
    </div>
  )
}

export default ExecutiveHero
