import React from 'react';
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import animationData from "../../../assets/json/enibo1.json";
import { images } from '../../../constants';


const containerVariants = {
    animate: {
      transition: {
        delayChildren: 2,
        staggerChildren: 0.6,
      },
    },
  };

const ContactAnime = () => {
  return (
    <motion.div
    variants={containerVariants}
    initial="initial"
    animate="animate"
    className="mt-6  justify-center lg:max-w-[400px]"
  >
   {/**
    <Lottie animationData={animationData} />
    */} 
    <img src={images.snail} alt="contact" className='rounded-lg'/>
  </motion.div>
  )
}

export default ContactAnime