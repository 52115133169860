import React,{useState} from 'react';
import MainLayout from '../../components/MainLayout';
import { FaRegCopy } from "react-icons/fa6";

const DonatePage = () => {
  const [copySuccess, setCopySuccess] = useState('');

// your function to copy here
  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  return (
    <MainLayout>
        <div className='max-w-[95vw] mt-[150px] md:mt-12 lg:mt-12 mx-auto text-center'>
        <h2 className="mb-12 text-3xl lg:text-4xl font-bold text-black">We Need<span className="text-eni_orange"> Donations</span></h2>
          <div className='flex flex-col space-y-4 md:space-y-0 lg:space-y-0 md:flex-row lg:flex-row md:items-start md:space-x-3 lg:items-start lg:space-x-3 justify-center'>
          <span className='bg-gray-400 rounded-lg  p-4 text-white'>
            <span className='text-bold text-2xl lg:text-3xl text-black/75'>Account Name </span><br/>
            <span className=''>Enibo Albert Charity Foundation</span>
          </span>
          <span className='bg-gray-400 rounded-lg  p-4 text-white'>
          <span className='text-bold text-2xl lg:text-3xl text-black/75'>Bank Name </span><br/>
            <span className=''>United Bank of Africa</span>
          </span>
          </div>
          
          <div className='inline-block mt-3 bg-gray-400 rounded-lg pr-20 pl-20 pt-4 pb-4  text-white'>
            <span className='text-bold text-2xl lg:text-3xl text-black/75'>Account Number</span><br/>
          <button onClick={() => copyToClipBoard('1025983969')}>
          <div className='flex flex-row items-center space-x-2'>
            <span className='text-2xl lg:text-3xl '>1025983969</span>
            <FaRegCopy/>
          </div> 
        </button><br/>
        <span className='text-eni_green bg-slate-300 rounded-lg '>
         {copySuccess}
         </span>
          </div>
        
         
     
        </div>
    </MainLayout>
    
  )
}

export default DonatePage