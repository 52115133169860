import { Link ,useNavigate} from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import { images } from "../../../../constants";
import { useEffect, useState } from "react";
import { AiFillDashboard, AiOutlineClose, AiOutlineMenu, AiOutlineLogout} from "react-icons/ai";
import { FaUser, FaYoutubeSquare, FaStar } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import NavItem from "./NavItem";
import NavItemCollapse from "./NavItemCollapse";
import { logout } from "../../../../store/actions/user";
import { useDispatch } from "react-redux";


const MENU_ITEMS = [
  {
    title: "Dashboard",
    link: "/admin",
    icon: <AiFillDashboard className="text-xl" />,
    name: "dashboard",
    type: "link",
  },
  {
    title: "Youtube Updates",
    link: "/admin/youtube/manage",
    icon: <FaYoutubeSquare className="text-xl" />,
    name: "Youtube",
    type: "link",
  },
  {
    title: "Post App News",
    content: [
      { title: "New", link: "/admin/posts/new" },
      { title: "Manage", link: "/admin/posts/manage" },
    ],
    icon: <MdDashboard className="text-xl" />,
    name: "posts",
    type: "collapse",
  },
  {
    title: "Orders",
    content: [
      { title: "Verify an order", link: "/admin/outreach/new" },
      { title: "Manage", link: "/admin/outreach/manage" },
    ],
    icon: <MdDashboard className="text-xl" />,
    name: "outreaches",
    type: "collapse",
  },
  {
    title: "Sellers",
    content: [
      { title: "Confirm a Seller", link: "/admin/project/new" },
      { title: "Manage sellers", link: "/admin/project/manage" },
    ],
    icon: <MdDashboard className="text-xl" />,
    name: "project",
    type: "collapse",
  },
  {
    title: "Users",
    content: [
      { title: "Create Admin User", link: "#" },
      { title: "Manage Users", link: "/admin/project/manage" },
    ],
    icon: <MdDashboard className="text-xl" />,
    name: "project",
    type: "collapse",
  },
  {
    title: "Profile",
    link: "/admin/youtube/manage",
    icon: <FaUser className="text-xl" />,
    name: "Youtube",
    type: "link",
  },
  
];

const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [activeNavName, setActiveNavName] = useState("dashboard");
  const windowSize = useWindowSize();

  const toggleMenuHandler = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  useEffect(() => {
    if (windowSize.width < 1024) {
      setIsMenuActive(false);
    } else {
      setIsMenuActive(true);
    }
  }, [windowSize.width]);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logoutHandler=()=>{
    dispatch(logout())
    navigate("/login")
  }

  return (
    <header className="flex h-fit w-full items-center justify-between p-4 lg:h-full lg:max-w-[300px] lg:flex-col lg:items-start lg:justify-start lg:p-0">
      {/* logo */}
      <Link to="/">
      <div className='mt-2 lg:hidden'>
      <span className=' text-black font-bold text-xl lg:text-3xl rounded-lg leading-10 font-newsreader pr-2 pl-2 flex flex-row'>FO<span className='text-eni_green'>U</span></span>
      </div>
      </Link>
      {/* menu burger icon */}
      <div className="cursor-pointer lg:hidden">
        {isMenuActive ? (
          <AiOutlineClose className="w-6 h-6 text-black" onClick={toggleMenuHandler} />
        ) : (
          <AiOutlineMenu className="w-6 h-6 text-black" onClick={toggleMenuHandler} />
        )}
      </div>
      {/* sidebar container */}
      {isMenuActive && (
        <div className="fixed inset-0 lg:static lg:h-full lg:w-full">
          {/* underlay */}
          <div
            className="fixed inset-0 bg-black opacity-50 lg:hidden"
            onClick={toggleMenuHandler}
          />
          {/* sidebar */}
          <div className="fixed top-0 bottom-0 left-0  w-3/4  bg-white p-4 lg:static lg:h-full lg:w-full lg:p-6">
            <Link to="/">
            <div className='mt-2'>
            <span className=' text-black font-bold text-xl lg:text-3xl rounded-lg leading-10 font-newsreader pr-2 pl-2 flex flex-row'>FO<span className='text-eni_green'>U</span></span>
        </div>
            </Link>
            <h4 className="mt-10 font-bold text-[#C7C7C7]">MAIN MENU</h4>
            {/* menu items */}
            <div className="mt-6 flex flex-col gap-y-[0.563rem]">
              {MENU_ITEMS.map((item) =>
                item.type === "link" ? (
                  <NavItem
                    key={item.title}
                    title={item.title}
                    link={item.link}
                    icon={item.icon}
                    name={item.name}
                    activeNavName={activeNavName}
                    setActiveNavName={setActiveNavName}
                  />
                ) : (
                  <NavItemCollapse
                    key={item.title}
                    title={item.title}
                    content={item.content}
                    icon={item.icon}
                    name={item.name}
                    activeNavName={activeNavName}
                    setActiveNavName={setActiveNavName}
                  />
                )
              )}
              <button onClick={logoutHandler} className="text-black text-3xl fixed bottom-0"><AiOutlineLogout/></button>
            </div>
            
          </div>
          
        </div>
       
      )}
       
    </header>
  );
};

export default Header;
