import React from "react";

const Admin = () => {
  return <div className="text-gray-600">Admin dashboard

    <div> </div>
  </div>;
};

export default Admin;
