import React from "react";
//import {useForm, Controller} from "react-hook-form";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { getSinglePostV1, updatePost } from "../../../../services/products";
import Editor from "../../../../components/editor_new/Editor"
import ArticleDetailSkeleton from "../../../articleDetail/components/ArticleDetailSkeleton";
import ErrorMessage from "../../../../components/ErrorMessage";
import axios from "axios";


const EditPostV2 = () => {
    const { id } = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const userState = useSelector((state) => state.user);
    const [content, setContent] = useState(null);
    const [newTitle, setNewTitle] = useState("");
    const [image, setImage] = useState("")
    //const [type, setType] = useState("")
    const [selectedOption, setSelectedOption] = useState('');
    const [fileInput, setFileInput] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    let loadingToast = "";
    
   
    //get the specific post by id to prepopulate the update fields
    const { data, isLoading, isError } = useQuery({
      queryFn: () => getSinglePostV1({ id }),
      queryKey: ["article", id],
      onSuccess: (data) => {
        setNewTitle(data?.data?.title);
        setImage(data?.data?.image);
        setSelectedOption(data?.data?.type)
      },
      refetchOnWindowFocus: false,
    });
    const publicId = data?.data?.public_id;
    console.log(publicId)
    

  //mutation to delete previous image if any, using the cloudinary image public_id
  const {
    mutate: mutateDeleteImage,
    isLoading: isLoadingDeleteImage,
  } = useMutation({
    mutationFn: ({ publicId }) => {
      return axios.post("https://eniboc.xyz/api/v1/deleteImage", { publicId });
    },
    onSuccess: () => {
      console.log("Image deleted successfully");
    },
    onError: (error) => {
      console.error("Error deleting image:", error);
    },
  });

// mutation for updating the post
    const {
      mutate: mutateUpdatePostDetail,
      isLoading: isLoadingUpdatePostDetail,
    } = useMutation({
      mutationFn: ({image,public_id, newTitle, id , content,type, token}) => {
        return updatePost({
          image,
          public_id,
          newTitle,
          content,
          type,
          id,
          token,
        });
      },
     
      onSuccess: (data) => {
        queryClient.invalidateQueries(["article", id]);
        //toast.success("Post is updated");
        navigate(`/admin/project/manage`, { replace: true });
      },
      onError: (error) => {
        toast.error(error.message);
        console.log(error);
      },
      onSettled: () => {
        // Optional: Perform any additional actions after mutation is settled
      },
    });
  
    const handleOptionChange = (e) => {
      setSelectedOption(e.target.value);
    };
    // handle post will trigger once the update button is clicked 
    const handleUpdatePost = async (e) => {
      try{
          // If there is a previous image on cloudinary associated with this post, delete it. before uploading a new one
          if (publicId) {
            await mutateDeleteImage({ publicId: publicId });
          }
          // Show loading state
          loadingToast = toast.loading("Updating post...");

        // Upload the image file to Cloudinary
          const data = await uploadToCloudinary(fileInput);
      
        mutateUpdatePostDetail({
         image: data.secure_url, // the image url
         public_id: data.public_id, // the image public_id gotten from the cloudinary response
         newTitle,
         content,
         type: selectedOption,
         id,
         token: userState.userInfo?.data?.token,
       });
       // Hide loading state on success
      toast.success("Post updated successfully");
       // Clear the loading state manually
      toast.dismiss(loadingToast);
      }catch (error) {
        // Handle errors during the update
      toast.error("Error updating post");
        console.error(error);
       
    };
  }

  //cloudinary function
  const uploadToCloudinary = async (file) => {
    const cloudName = process.env.REACT_APP_CLOUD_NAME;
     // Replace with your Cloudinary upload preset
    const uploadPreset = process.env.REACT_APP_CLOUD_UPLOAD_PRESET; 

    //const quality = 50;
    //const resizeOptions = "c_scale,q_52,w_1433";

    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", uploadPreset);
      //formData.append("quality", quality.toString());
      //formData.append("eager", resizeOptions);

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      //const imageUrl = data.secure_url;
      return data;
    }
  };

 
  
  // handle image file input changes
const handleFileInputChange = (e) => {
  const selectedFile = e.target.files[0];

  if (selectedFile) {
    // Check if the file size is less than or equal to 2GB (in bytes)
    const maxSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes
    if (selectedFile.size <= maxSize) {
      setFileInput(selectedFile);

      // Display a thumbnail preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      // File size exceeds 2GB, show an error message to the user
      console.error('File size exceeds 2GB limit.');
      // You may want to display an error message to the user
      setErrorMessage('File size exceeds 2GB limit.');
    }
  }
};

   
    let isPostDataLoaded = !isLoading && !isError;

    const isFormValid = newTitle.trim() !== "" && content !== null && fileInput !== null && selectedOption !== null; 
    return (
      <div>
        <div className="text-gray-600"> EDIT PROJECTS </div>
        {isLoading ? (
          <ArticleDetailSkeleton />
        ) : isError ? (
          <ErrorMessage message="Couldn't fetch the post detail" />
        ) : (
          <section className="container mx-auto max-w-5xl flex flex-col px-5 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
            <article className="flex-1">
              <div className="d-form-control w-full">
                <label className="d-label" htmlFor="title">
                  <span className="d-label-text">Title: </span>
                </label>
                <input
                  id="newTitle"
                  value={newTitle}
                  className="d-input d-input-bordered border-slate-300 !outline-slate-300 text-xl font-medium font-roboto text-dark-hard"
                  onChange={(e) => setNewTitle(e.target.value)}
                  placeholder="title"
                />
              </div>
              <div className="w-full mb-6 border border-gray-300 p-2">
                {isPostDataLoaded && (
                  <Editor
                    content={data?.data?.content}
                    editable={true}
                    onDataChange={(data) => {
                      setContent(data);
                    }}
                  />
                )}
              </div>
              <div className="d-form-control w-full mt-4 ">
            <label className="d-label" htmlFor="fileInput">
              <span className="d-label-text">Image</span>
            </label>
            <input
              type="file"

              className="d-input d-input-bordered border-slate-300 !outline-slate-300 text-xl font-medium font-roboto text-dark-hard"
              onChange={handleFileInputChange}
            />
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Thumbnail Preview"
                className="mt-2 w-20 h-20 object-cover"
              />
              
            )}
            <div className="text-red-500 my-3"> {errorMessage}</div>
          </div>
          <div className="d-form-control w-full mb-6">
          <label className="d-label" htmlFor="projectType">
            <span className="d-label-text">Project Type</span>
          </label>
          <div>
            <label className="inline-block mb-2 mr-4">
              <input
                type="radio"
                name="projectType"
                value="Rural Impact"
                checked={selectedOption === 'Rural Impact'}
                onChange={handleOptionChange}
                className="mr-2"
              />
              Rural Impact
            </label>
            <label className="inline-block mb-2">
              <input
                type="radio"
                name="projectType"
                value="Project SS"
                checked={selectedOption === 'Project SS'}
                onChange={handleOptionChange}
                className="mr-2"
              />
              Project SS
            </label>
          </div>
        </div>
              <button
                disabled={ !isFormValid || isLoadingUpdatePostDetail}
                type="button"
                onClick={handleUpdatePost}
                className=" ml-[60vw] bg-green-500 text-white font-semibold rounded-lg px-4 py-2 disabled:cursor-not-allowed disabled:opacity-70"
              >
                 {isLoadingUpdatePostDetail ? "Updating..." : "Update Post"}
              </button>
            </article>
          </section>
        )}
      </div>
    );
  };

export default EditPostV2;