// ScrollToTopButton.js
import React, { useState, useEffect } from 'react';
import { HiArrowNarrowUp } from 'react-icons/hi';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show the button when user scrolls down 300px
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div
      className={`fixed bottom-10 right-10 bg-eni_green text-white py-2 px-4 rounded cursor-pointer transition-opacity duration-300 ${
        isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
      } hover:bg-eni_orange`}
      onClick={scrollToTop}
    >
      <HiArrowNarrowUp className="animate-pulse"/>
    </div>
  );
};

export default ScrollToTopButton;
