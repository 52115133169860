// ThemeContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const storedDarkMode = localStorage.getItem('darkMode');
  const [darkMode, setDarkMode] = useState(storedDarkMode ? JSON.parse(storedDarkMode) : false);

  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => {
      const newDarkMode = !prevDarkMode;
      localStorage.setItem('darkMode', JSON.stringify(newDarkMode));
      return newDarkMode;
    });
  };

  // Dynamically set background based on darkMode state
  const background = darkMode ? '#333' : '#fff';
  

  useEffect(() => {
    // Update the background in response to darkMode changes
    document.body.style.background = background;
    
  }, [background]);

  const value = {
    darkMode,
    toggleDarkMode,
    background, // Include background in the context value
  };

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};
