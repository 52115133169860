import React from 'react';
import MainLayout from '../../components/MainLayout';
import Hero from './container/Hero';
import Articles from './container/Articles';
import Products from './container/Products';
import { useTheme } from '../../components/ThemeContext';
import {motion} from "framer-motion";

const LandingPage = () => {
  const { darkMode, background } = useTheme();
  return (
  <div style={{ color: darkMode ? '#fff' : '#333', background }}>
  <MainLayout  darkMode={darkMode}>
   <Hero darkMode={darkMode}/>
   <Products />
    <motion.div initial={{ x: -300, opacity: 0 }}  whileInView={{ x: 0, opacity: 1 }} exit={{ x: 300, opacity: 0 }} transition={{ duration: 1.1, ease: "easeInOut" }}>
      <Articles darkMode={darkMode}/>
   </motion.div>

  </MainLayout>
  </div> 
  )  
}

export default LandingPage;
