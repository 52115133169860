import React from 'react';
import MainLayout from '../../components/MainLayout';
import AboutIntro from './container/AboutIntro';
import {AiOutlineCheck} from "react-icons/ai";
import { motion } from 'framer-motion';

const AboutPage = () => {
  return (
    <MainLayout>  
<div className="container max-w-[90vw]  mt-24 lg:mt-5 mx-auto md:px-6">
 <AboutIntro/> 
</div>
    </MainLayout>
    
  )
}

export default AboutPage
