import React from "react";


const SellerDetails = ({ className, header, id, name, address}) => {

  return (
    <div
      className={`w-full shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] rounded-lg p-4 ${className}`}
    >
      <h2 className={`text-black font-roboto font-medium  md:text-xl`}>
        {header}
      </h2>
      <div className="grid gap-y-5 mt-5 md:grid-cols-2 md:gap-x-5 lg:grid-cols-2">
       
          <div
            key={id}
            className="flex space-x-3 flex-nowrap items-center"
          >
            <div className="text-sm font-robotofont-medium">
              <p>Seller Business Name</p>
              <h3 className={`text-sm font-roboto text-black font-medium md:text-base lg:text-md`}>
                {name}
              </h3>
              <span className={`text-xs opacity-60 text-black`}>
                Location: <br/>{address}
              </span>
            </div>
          </div>
     
      </div>
    </div>
  );
};

export default SellerDetails;
