import React from "react";
import { Link } from "react-router-dom";
                                        
const ArticleCard = ({ post, className, darkMode}) => {
  return (
    <div
    className={`rounded-xl overflow-hidden shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] ${className}`}
  >
    <Link to={`/shop/product/${post.productId}`}>
      <img
        src={
          post.image
           
        }
        alt={post.productName}
        className="w-full object-cover object-center h-48 md:h-52 lg:h-48 xl:h-60 hover:scale-105 duration-200"
      />
    </Link>
    <div className="p-5">
    <div className="flex justify-between flex-nowrap items-center ">
       
        <span className={`font-bold ${darkMode ? "text-dark-light": "text-dark-hard"} italic text-sm md:text-base`}>
          {post.productMeasurement}
        </span>
        <div>
          <h3 className="text-dark-light">₦{post.productPrice}</h3>
        </div>
      </div>
      <Link to={`/shop/product/${post.productId}`}>
        <h2 className={`font-roboto font-bold text-xl mt-6 ${darkMode ? "text-neutral-400": "text-dark-soft"}  md:text-2xl lg:text-[18px]`}>
          {post.productName}
        </h2>  
      </Link>
      
    </div>
    <Link to={`/shop/product/${post.productId}`} className="text-center">
       <button className="p-2 rounded-lg bg-eni_green text-white font-bold mb-2 ml-2 "> BUY</button>  
    </Link>
  </div>
  );
};

export default ArticleCard;
