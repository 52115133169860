import farmer1 from "../assets/bom/bom farmer.jpg";
import farmer2 from "../assets/bom/farmer man.avif";
import farmer3 from "../assets/bom/farm lady.avif";
import farmer4 from "../assets/bom/oil farmer.webp";
import food from "../assets/bom/Foodstuff.jpg";
import snail from "../assets/bom/snail.jpg";
import fish1 from "../assets/bom/catfish.jpg";
import fish2 from "../assets/bom/local fish.jpg";
import market1 from "../assets/bom/local market.png";
import market2 from "../assets/bom/market 1.webp";
import market3 from "../assets/bom/market 2.jpg";
import market4 from "../assets/bom/market 3.jpg";
import market5 from "../assets/bom/market 4.webp";
import fishmarket from "../assets/bom/fish market.jpg";
import oil from "../assets/bom/oil.jpg";
import plantain1 from "../assets/bom/plantain1.jpg";
import plantain2 from "../assets/bom/plantain2.jpg";
import ugu from "../assets/bom/ugu.jpg";
import madiga from "../assets/bom/madiga.jpg";
import garri1 from "../assets/bom/garri.jpg";
import garri2 from "../assets/bom/garri2.jpg";
import drygarri from "../assets/bom/dry garri.jpg";
import palmnut from "../assets/bom/palmnut.jpg";





const images = {
    farmer1,farmer2,farmer3,farmer4,food,snail,fish1,fish2,market1,market2,market3,market4,market5,
    fishmarket,oil,plantain1,plantain2,ugu,madiga,garri1, garri2, drygarri,palmnut
}

export default images;