import axios from "axios";

export const login = async ({ phoneNumber, password}) => {
    try {
        
        const {data} = await axios.post('http://letsoutbound.xyz.musafx.com/api/v1/login', {
            phoneNumber,
            password,
        })
        return data;
    }catch(error){
        if(error.response && error.response.data.message)
         throw new Error(error.response.data.message);
        throw new Error(error.message); 
    }
}
export const getLoggedInUser = async ({token}) => {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        const {data} = await axios.get('http://letsoutbound.xyz.musafx.com/api/v1/getloginuser', config);
        return data;
    }catch(error){
        if(error.response && error.response.data.message)
         throw new Error(error.response.data.message);
        throw new Error(error.message); 
    }
}


