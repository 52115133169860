import React from 'react';
import MainLayout from '../../components/MainLayout';
import  {images}  from '../../constants';
import { motion } from 'framer-motion';
const FaqPage = () => {
  return (
    <MainLayout>
    <div 
    className="container my-20 mx-auto md:px-6">
    
      <section className="mb-6">
      
        <div className="container mx-auto text-center lg:text-left xl:px-32">
          <div className=" grid items-start lg:grid-cols-2">
            <div className="mb-12 lg:mb-0">
              <div
                className="block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  md:px-12 lg:-mr-14 backdrop-blur-[30px]">
                <h3 className="mb-3 text-2xl font-bold text-black">
                  We know how valuable your time is
                </h3>
                <h5 className="mb-12 text-lg font-bold text-eni_orange  lg:mb-10 xl:mb-12">
                  Let us answer your questions
                </h5>

                <p className="mb-4 font-bold text-black">
                How can I start selling my products on FOU?
                </p>
                <p className="mb-6 text-gray-500 ">
                Getting started on FOU is easy! Simply sign up as a seller, create your storefront, and list your locally-produced goods to reach a wider audience within our community.
                </p>

                <p className="mb-4 font-bold text-black">
                Is there any support available for new sellers on FOU?

                </p>
                <p className="mb-6 text-gray-500 ">
                Absolutely! We provide comprehensive support and resources to assist new sellers every step of the way, from setting up their storefronts to optimizing their listings and navigating our platform's features. 
                </p>

                <p className="mb-4 font-bold text-black">
                How does FOU ensure the authenticity and quality of locally-sourced products?
                </p>
                <p className="text-gray-500 mb-6">
                At FOU, we prioritize authenticity and quality. We verify sellers and closely monitor product listings to ensure that all items featured on our platform are genuinely locally-produced, fostering trust and confidence among buyers.
                </p>

                <span className="italic font-bold text-black">Please note that these are general answers to common questions, and for more specific information or inquiries, we encourage individuals to reach out to us directly.</span>
              </div>
            
            </div>

            <div>
              <img src={images.fish2}
                className="w-full rounded-lg shadow-lg " alt="" />
            </div>
          </div>
        </div>
      
      </section>

    </div>


    </MainLayout>
    
  )
}

export default FaqPage
