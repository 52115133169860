import React, { useState } from "react";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs";
import MainLayout from "../../components/MainLayout";
import SocialShareButtons from "../../components/SocialShareButtons";
import SuggestedPosts from "./container/SuggestedPosts";
import { useQuery } from "@tanstack/react-query";
import { getAllPosts, getSinglePost } from "../../services/posts";
import ArticleDetailSkeleton from "./components/ArticleDetailSkeleton";
import ErrorMessage from "../../components/ErrorMessage";
//import { useSelector } from "react-redux";
import { useTheme } from "../../components/ThemeContext";
import { motion } from "framer-motion";


const ArticleDetailPage = () => {
  const {darkMode} = useTheme();
  const { id } = useParams();
  //const userState = useSelector((state) => state.user);
  const [breadCrumbsData, setbreadCrumbsData] = useState([]);
  //const [body, setBody] = useState(null);


  const { data, isLoading, isError } = useQuery({
    queryFn: () => getSinglePost({ id }),
    queryKey: ["article", id],
    onSuccess: (data) => {
      setbreadCrumbsData([
        { name: "Home", link: "/" },
        { name: "Posts", link: "/article" },
        { name: "Article title", link: `/articles/${data?.data?.id}` },
      
      ]);
      
      console.log(data)
    },
  });

  const { data: postsData } = useQuery({
    queryFn: () => getAllPosts(),
    queryKey: ["posts"],
  });

  return (
    <MainLayout>
      {isLoading ? (
        <ArticleDetailSkeleton />
      ) : isError ? (
        <ErrorMessage message="Couldn't fetch the post detail" />
      ) : (
        <section className="container mx-auto max-w-7xl flex flex-col px-4 md:px-10 lg:px-10 py-5 lg:flex-row lg:gap-x-5 lg:items-start">
          <article 
          className="flex-1">
            <BreadCrumbs data={breadCrumbsData} darkMode={darkMode}/>
            
            <h1 className={`${darkMode ? "text-neutral-200" : "text-black"} text-xl font-medium font-roboto mt-4 md:text-[26px]`}>
              {data?.data?.title}
            </h1>
            <div className="w-full lg:w-4/5 mt-8">
              {!isLoading && !isError && (
                <div> <img src={data?.data?.image} alt="post " className="w-full  lg:h-[500px] rounded-lg"/>
                  <p className={`mt-8 w-full text-neutral-500 text-justify`}>
                  <div dangerouslySetInnerHTML={{ __html: data?.data?.content}} />
                  </p>
                  
                </div>
                
              )}
            </div>
          
          </article>
          <div>
            <div >
            <SuggestedPosts darkMode={darkMode}
              header="Latest Article"
              posts={postsData?.data?.data}
              className={` mt-8 lg:mt-0 lg:max-w-xs lg:ml-10 `}
            />
            </div>
          
            <motion.div initial={{ x: 300, opacity: 0 }}  whileInView={{ x: 0, opacity: 1 }} exit={{ x: -300, opacity: 0 }} transition={{ duration: 1.3, ease: "easeInOut" }}
            className="mt-7 lg:ml-10">
              <h2 className={`text-black font-roboto font-medium  mb-4 md:text-xl`}>
                Share now on:
              </h2>
              <SocialShareButtons darkMode={darkMode}
                url={encodeURI(window.location.href)}
                title={encodeURIComponent(data?.data?.title)}
              />
            </motion.div>
          </div>
        </section>
      )}
    </MainLayout>
  );
};

export default ArticleDetailPage;