import { motion } from "framer-motion";
import { images } from "../../../constants";
import { TypeAnimation } from "react-type-animation";
import { HiArrowNarrowDown, HiCalendar } from "react-icons/hi";
import { Link } from "react-router-dom";
const containerVariants = {
  animate: {
    transition: {
      delayChildren: 2,
      staggerChildren: 0.6,
    },
  },
};

const Hero = ({image, title, text}) => {
  return (
    <div className="flex flex-col lg:flex-row mt-6 lg:mt-[20px]  items-start lg:items-start justify-between max-[960px]:flex-col px-32 max-[960px]:px-16 max-[560px]:px-8 ">
      <div className="lg:mr-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5 }}
          className="w-full "
        >
          <div className="flex">
            <h1 className="mb-4 text-secondaryColor text-6xl uppercase font-semibold max-[960px]:text-5xl max-[560px]:text-4xl">
              {title}
            </h1>
          </div>

          <div className="font-semibold text-sm lg:text-2xl lg:mb-6">
            <TypeAnimation
              sequence={[
                "Shop Locally With Ease",
                1000,
                "Indigeneous buying and selling",
                1000,
                "Enriching the Local produces",
                1000,
      
              ]}
              wrapper="span"
              speed={50}
              style={{  display: "inline-block" }}
              repeat={Infinity}
            />
          </div>
          <p className="py-2 text-base text-gray-600  tracking-wider mt-4 text-justify" style={{ maxWidth: '400px' }}>
          {text}
          </p>
          
            <div className="mt-6 flex items-center space-x-6">
            <Link to="/shop" spy={true} smooth={true} offset={-100}>
            <button className="py-4 rounded-lg bg-eni_green border border-eni_green font-semibold text-white w-[12vw] max-[1080px]:w-[16vw] max-[800px]:w-[20vw] max-[650px]:w-[30vw] max-[500px]:w-[38vw] max-[340px]:w-[42vw] px-4 text-md uppercase ">
              ABEG BUY 
            </button>
            </Link>
            <Link to="whatawaits" spy={true} smooth={true} offset={-100}>
            <div className="flex items-center">
              <button className="text-gray-600 font-bold text-md uppercase">
               Explore our Local marketplace ?
              </button>
              <HiArrowNarrowDown className="text-eni_green text-2xl ml-2 animate-bounce" />
            </div>
            </Link>
          </div>
        </motion.div>
      </div>
      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
        className="mt-6  justify-center"
      >
        <img src={image} className="lg:h-[400px] rounded-lg" alt='bom_'/>
      </motion.div>
    </div>
  );
};

export default Hero;
