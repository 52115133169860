import React from "react";
import { useQuery} from "@tanstack/react-query";
import { useEffect, useState, useMemo } from "react";
import { getAllYoutube} from "../../../../services/youtube";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import PaginationV1 from "../../../../components/PaginationV1";




let isFirstRun = true;
let PageSize = 3;
const ManageYoutubes = () => {
  //const articleId = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { data, refetch } = useQuery({
    queryFn: () => getAllYoutube(currentPage),
    queryKey: ["articles"],
    
    onError: (error) => {
      toast.error(error.message);
      console.log(error);
    },
    
  }
  );
  
 //pagination useMemo hook
  const currentTableData = 
    useMemo(() => {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      return  data?.data?.data?.slice(firstPageIndex, lastPageIndex);
    }, [data?.data?.data, currentPage]);
  


useEffect(() => {
  if (isFirstRun) {
    isFirstRun = false;
    return;
  }
  refetch();
}, [refetch]);

  


  return <div className="">
      <div className="text-gray-600">MANAGE OUTREACHES</div>

      {/** table component */}
      
      <div className="overflow-x-hidden mt-[-50px] lg:mt-[-150px]">
  <div className="max-w-screen min-h-screen bg-gray-100 flex items-center justify-center overflow-hidden">
    <div className="w-full lg:w-5/6">
      <div className="bg-white shadow-md rounded my-6">
        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-2xl leading-normal">
                <th className="py-3 px-6 text-left">ID</th>
                <th className="py-3 px-6 text-left">Link</th>
                <th className="py-3 px-6 text-center">Edit</th>
              </tr>
            </thead>
            <tbody className="text-black text-xl font-light">
              {currentTableData?.map((posts) => (
                <tr key={posts.youtubeId} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left">
                    <div className="flex items-center">
                      <span>{posts.youtubeId}</span>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-left">
                    <div className="flex items-center justify-center">
                      <h6>{posts.url}</h6>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <div className="flex items-center justify-center">
                      <div
                        onClick={() => navigate(`edit/${posts.youtubeId}`)}
                        className="w-4 mr-2 transform hover:text-green-500 hover:scale-120 cursor-pointer"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <PaginationV1
          className="pagination-bar mx-auto"
          currentPage={currentPage}
          totalCount={data?.data?.data?.length || 10} //data?.data?.data?.length
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  </div>
</div>

    
    </div>

    
};

export default ManageYoutubes;