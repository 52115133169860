import axios from "axios";

export const getAllYoutube = async (searchKeyword = "", page = 1, limit = 10) => {
  try {
    const { data, headers } = await axios.get(
      `https://eniboc.xyz/api/v1/youtube/getallyoutube/?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`
    );
    return { data, headers };
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};


export const createNewYoutube = async ({ url, description, token}) => {
  try {
    const config = {
      headers: {
          Authorization: `Bearer ${token}`,
         'Content-Type': 'multipart/form-data',      
      },
  }
    const { data } = await axios.post(
      `https://eniboc.xyz/api/v1/createyoutube`,
      {
        url, description, token
      }, config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const deletePost = async ({outreachId, token}) => {
  try {
    const config = {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  }
    const { data } = await axios.delete(
      `https://eniboc.xyz/api/v1/deleteyoutube/${outreachId}`,
       config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const updatePost = async ({id, url, newDescription, token}) => {
  try {
    const config = {
      headers: {
          Authorization: `Bearer ${token}`, 
      },
  }
    const { data } = await axios.put(
      `https://eniboc.xyz/api/v1/updatebyyoutube/${id}`,
      {
        url, newDescription, id, token
      }, config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const getSinglePostV1 = async ({id}) => {
  try {
    const { data } = await axios.get(
      `https://eniboc.xyz/api/v1/youtube/${id}`
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};